import { pricingRuleController } from "./pricingRuleController";
import { message} from "antd";


export const addProductToCart = async (addToCart, qty, totalQtyFlag,cart,setCart,setSelectedProductInCart,deleteCart,processTotalManualDiscount,setLoader,setProductsList,setSelectCategotyList,setSelectedProductBrand,salesRepresentDefaultLine,tillData,cartRef,productsCopy,salesRepresent,orderType) => {
    const weight = parseFloat(qty);
    const addedToCart = cart.items;
    const index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId && p.isReturn === false);
    if (index >= 0 && !addedToCart?.[index]?.parkedItem) {
      addedToCart[index].weight = totalQtyFlag ? parseFloat(weight.toFixed(addToCart.isQtyDesimal)) : parseFloat(addedToCart[index].weight) + parseFloat(weight);
      addToCart.weight = parseFloat(addedToCart[index].weight.toFixed(addToCart.isQtyDesimal));

      if (parseFloat(addedToCart[index].weight) === 0) {
        setSelectedProductInCart({});
        addedToCart.splice(index, 1);
      } else {
        let manualDiscountInput = 0;
        if (addedToCart[index].discountType === "PD") {
          manualDiscountInput = addedToCart[index].discountValue;
        }
        const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
        addedToCart[index].discount = totalQtyFlag ? discountAmt * weight : discountAmt * addedToCart[index].weight;
        const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
        const mrp = totalQtyFlag ? parseFloat(sp) * weight : parseFloat(sp) * addedToCart[index].weight;
        const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
        addedToCart[index].taxAmount = tax;
        addedToCart[index].nettotal = mrp;
        delete addedToCart[index].nextRule;
        message.success(`${addedToCart[index].name} Added Successfully`, 0.1);
      }
    } else {
      if (parseFloat(weight) !== 0) {
        addToCart.weight = parseFloat(weight.toFixed(addToCart.isQtyDesimal));
        delete addToCart.nextRule;
        const mrp = parseFloat(addToCart.salePrice) * addToCart.weight;
        const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
        addToCart.taxAmount = tax;
        addToCart.nettotal = mrp;
        addToCart.salesRepId = salesRepresentDefaultLine.salesRepresentId;
        addToCart.salesRepName = Object.keys(salesRepresent).length > 0 ? salesRepresentDefaultLine.name : "";
        addedToCart.unshift(addToCart);
        message.success(`${addToCart.name} Added Successfully`, 0.1);
      }
    }

    let totalTax = 0;
    let totalPrice = 0;
    let totalItemsQty = 0;
    let totalDiscounts = 0;
    for (let i = 0; i < addedToCart.length; i += 1) {
      totalPrice += addedToCart[i].nettotal;
      totalItemsQty += addedToCart[i].weight;
      totalTax += addedToCart[i].taxAmount;
      totalDiscounts += addedToCart[i].discount;
      addedToCart[i].key = i;
    }

    const roundOffValue = Math.round(totalPrice);
    const totalRoundOff = totalPrice - roundOffValue;
    if (cart.items.length === 0) {
      deleteCart();
    }
    let cartObj = {
      ...cart,
      items: [...addedToCart],
      total: parseFloat(totalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
      tax: totalTax,
      discount: totalDiscounts,
      totalQty: totalItemsQty,
      roundOff: totalRoundOff,
    };
    let updatedCart = await pricingRuleController(addToCart, cartObj, cart, setCart, cartRef, orderType);
    
    if (cart.manualDiscountApplied && cart.manualDiscountApplied !== 0) {
      setLoader(true);
      setTimeout(() => {
        processTotalManualDiscount(cart.manualDiscountApplied);
        setLoader(false);
      }, 200);
    }
  
    let updatedTotalTax = 0;
    let updatedTotalPrice = 0;
    let updatedTotalItemsQty = 0;
    let updatedTotalDiscounts = 0;
    for (let i = 0; i < updatedCart.items.length; i += 1) {
      updatedTotalPrice += updatedCart.items[i].nettotal;
      updatedTotalItemsQty += updatedCart.items[i].weight;
      updatedTotalTax += updatedCart.items[i].taxAmount;
      updatedTotalDiscounts += updatedCart.items[i].discount;
      updatedCart.items[i].key = i;
    }
  
    const updatedRoundOffValue = Math.round(updatedTotalPrice);
    const updatedTotalRoundOff = updatedTotalPrice - updatedRoundOffValue;
  
    let finalCartObj = {
      ...updatedCart,
      items: [...updatedCart.items],
      total: parseFloat(updatedTotalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
      tax: updatedTotalTax,
      discount: updatedTotalDiscounts,
      totalQty: updatedTotalItemsQty,
      roundOff: updatedTotalRoundOff,
    };
    setCart(finalCartObj);
    localStorage.setItem("cartObj",JSON.stringify(finalCartObj));
    setProductsList(productsCopy.slice(0, 100));
    setSelectCategotyList();
    setSelectedProductBrand();
    // quantityInputRef.current.focus();
    // quantityInputRef.current.select();
  };
