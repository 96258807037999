export const PackageDiscount = async (product, pricingRule, setCart, cart, orderType, cartObj, cartRef) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
      const saleTypeValidation = (pricingRule?.cwrSaletypeId === null || orderType === undefined) ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletypeId;
      let excludingFlag = true;

      if (pricingRule.mPricingB2CCustomerSegments.length > 0) {
        const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find((pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId);
        
        if ((pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) || (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment)) {
          excludingFlag = false;
        }
      }
  
      if (pricingRule.mPricingB2CCustomers.length > 0) {
        const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
        
        if ((pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) || (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer)) {
          excludingFlag = false;
        }
      }
    
      if (pricingRule.mPricingPcategories.length > 0) {
        const matchingCategoy = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === product.mProductCategoryId);
      
        if ((pricingRule.excludeProductCategories === "Y" && matchingCategoy) || (pricingRule.excludeProductCategories !== "Y" && !matchingCategoy)) {
          excludingFlag = false;
        }
      }
    
      if (pricingRule.mPricingBrands.length > 0) {
        const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === product.productBrandId);
      
        if ((pricingRule.excludeBrands === "Y" && matchingBrand) || (pricingRule.excludeBrands !== "Y" && !matchingBrand)) {
          excludingFlag = false;
        }
      }
    
      if (pricingRule.mPricingBUnits.length > 0) {
        excludingFlag = pricingRule.mPricingBUnits.some((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
      }
      
      if (pricingRule.mPricingXProducts.length > 0) {
        const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === product.productId);
      
        if ((pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) || (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1)) {
          excludingFlag = false;
        }
      }

      let pricingRuleFlag = true;
      if(pricingRule.mPricingXProducts.length>0){
        pricingRule.mPricingXProducts.some((op) => {
          cartObj.items.map((product)=> {
            if(op.mProductId === product.productId && product.nextRule === "Y"){
              pricingRuleFlag = false;
            }
          })
        })
      }else{
        if(product.nextRule === "Y"){
          pricingRuleFlag = false;
        }
      }

      if (saleTypeValidation && excludingFlag && pricingRuleFlag) {
        const addedToCart = cartObj.items;
        const offerProducts = pricingRule.mPricingXProducts;
        const prodIndexes = [];
        const properWeights = [];
        let productsPriceTotal = 0;

        addedToCart.map((item, index) => {
          const ifExistis = offerProducts.findIndex((op) => op.mProductId === item.productId);
          if (ifExistis >= 0) {
            prodIndexes.push(index);
            properWeights.push(item.weight);
            productsPriceTotal += parseFloat(item.realPrice);
          }
          return null;
        });

        if (prodIndexes.length === offerProducts.length) {
          if (properWeights.every((item) => item === properWeights[0])) {
            prodIndexes.map((index) => {
              let distributedDiscount = addedToCart[index].realPrice - (addedToCart[index].realPrice / productsPriceTotal) * pricingRule.fixedUnitPrice
              const sp = (addedToCart[index].realPrice / productsPriceTotal) * pricingRule.fixedUnitPrice;
              const mrp = parseFloat(sp) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].salePrice = sp;
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = Math.abs(distributedDiscount);
              addedToCart[index].discountName = pricingRule.printedName;
              addedToCart[index].mPricingruleId = pricingRule.mPricingrulesId;
              // message.success(pricingRule.printedName);
              return null;
            });
          } else {
            prodIndexes.map((index) => {
              const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
              const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
              addedToCart[index].taxAmount = tax;
              addedToCart[index].nettotal = mrp;
              addedToCart[index].discount = 0;
              addedToCart[index].discountName = "";
              addedToCart[index].mPricingruleId = null;
              return null;
            });
          }
        } else {
          prodIndexes.map((index) => {
            const mrp = parseFloat(addedToCart[index].realPrice) * addedToCart[index].weight;
            const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
            addedToCart[index].taxAmount = tax;
            addedToCart[index].nettotal = mrp;
            addedToCart[index].discount = 0;
            addedToCart[index].discountName = "";
            addedToCart[index].mPricingruleId = null;
            return null;
          });
        }

        let totalTax = 0;
        let totalPrice = 0;
        let totalItemsQty = 0;
        let totalDiscounts = 0;
        for (let i = 0; i < addedToCart.length; i += 1) {
          totalPrice += addedToCart[i].nettotal;
          totalItemsQty += addedToCart[i].weight;
          totalTax += addedToCart[i].taxAmount;
          totalDiscounts += addedToCart[i].discount;
          addedToCart[i].key = i;
        }

        const roundOffValue = Math.round(totalPrice);
        const totalRoundOff = totalPrice - roundOffValue;
        totalPrice = roundOffValue;

        setCart({
          ...cartObj,
          items: [...addedToCart],
          total: totalPrice,
          tax: totalTax,
          discount: totalDiscounts,
          totalQty: totalItemsQty,
          roundOff: totalRoundOff,
        });
      }
}