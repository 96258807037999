import { useState, useEffect } from "react";
import Axios from "axios";
import db from "../../database";
import Password from "antd/lib/input/Password";

const HCPrint = (printReciptData,PStatus) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  let roundOffValue = 0

  let tableDetails = JSON.parse(localStorage.getItem("tableName"));
  let salesRep = tillData.tillAccess.csBunit.salesRep.filter((item) => item.salesRepresentId === tableDetails?.waiter)
  if (salesRep.length > 0) {
    printReciptData.waiter = salesRep[0]?.name;
  }
  const mergedData = printReciptData.items.reduce((result, item) => {
    const existingItem = result.find(i => i.productId === item.productId && item.canceled !== "Y");
    if (existingItem) {
      existingItem.weight += item.weight;
      existingItem.nettotal += item.nettotal;
    } else if (item.canceled !== "Y") {
      result.push({ ...item });
    }
    return result;
  }, []);
  printReciptData.items = mergedData;
  let customerFlag = false
  let Payments1
  let taxDetails ='';

  printReciptData.payments.map((item) => {
    if (item.name === "Credit") {
      customerFlag = true
    }
    Payments1 += `
  <line>
      <text align="left" length="13"> ${item.name}</text>
      <text align="left" length="13">${item.amount}</text>
      <text align="left" length="13">${item.isoCode}</text>
  </line>`
  })
  const groupedItems = {};
   printReciptData.items.forEach(item => {
    const key = item.taxRate;
  if (!groupedItems[key]) {
    groupedItems[key] = [];
  }
  groupedItems[key].push(item);
  });
  for (const taxRate in groupedItems) {
    const totalTaxAmount = groupedItems[taxRate].reduce((total, item) => total + item.taxAmount, 0);
    const totalNetTotal = groupedItems[taxRate].reduce((total, item) => total + item.nettotal, 0);
  
    taxDetails += `<line>
      <text align="left" length="10">${taxRate}</text>
      <text align="left" length="10">${(totalNetTotal - totalTaxAmount).toFixed(2)}</text>
      <text align="left" length="10">${totalTaxAmount.toFixed(2)}</text>
      <text align="left" length="12">${totalNetTotal.toFixed(2)}</text>
    </line>`;
  }
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  let itemsDetails = '';
  let discountDetails = '';
  let staffDetails = '';
  let changeDetails = '';
  let OrderType = 'Tax Invoice';
  let ArabicOrderType ='فاتورة ضريبية'
  let ORDER_TYPE1_TEXT = '';
  let paymentTypeAndAmount ='';
  let negativeTotalAndQty = false;
  let showOnlyPaymentMethod = false;
  let paymentStatus = PStatus ? 'This receipt is closed':'You have paid';
  let arabicStatus =PStatus ? 'هذا الإيصال مغلق':'لقد دفعت'
  if(printReciptData.items.length > 0 && printReciptData.paid === 0 && !printReciptData.isReturn && !printReciptData.layAway ){
    paymentStatus = 'Draft';
    arabicStatus = 'مسودة'
  }
  else if(printReciptData.items.length > 0 && printReciptData.paid === 0 && printReciptData.isReturn && !printReciptData.layAway ){
    paymentStatus = 'Return Draft';
    arabicStatus = 'مسودة العودة'
  }
  if (printReciptData.isReturn ) {
    OrderType = 'Tax Credit Note'
    ArabicOrderType = 'مذكرة الائتمان الضريبي'
    ORDER_TYPE1_TEXT = `<line size="1">
    <text align="center" length="42">Return</text>
</line>`
    negativeTotalAndQty = true
    ArabicOrderType ='مذكرة الائتمان الضريبي'
  }
  if(printReciptData.isReturn && printReciptData.paid !== 0){
    paymentStatus = 'Returned'
    arabicStatus = 'تم العودة'
  }
  else if (printReciptData.layAway === "Y") {
    OrderType = 'Tax Invoice'
    ArabicOrderType= 'فاتورة ضريبية'
    ORDER_TYPE1_TEXT = `<line size="1">
    <text align="center" length="42">LAYAWAY</text>
</line>`
    negativeTotalAndQty = false
  }
   if (printReciptData.layAway === "Y" && printReciptData.paid !== 0) {
    showOnlyPaymentMethod = true
    paymentStatus = 'This receipt is closed'
    arabicStatus ='هذا الإيصال مغلق'
  }
   if (printReciptData.layAway === "Y" && printReciptData.paid === 0) {
    paymentStatus = 'NOT PAID'
    arabicStatus = 'غير مدفوعة'
  }
  
  printReciptData.items.forEach(item => {
    itemsDetails += `<line>
      <text align="left" length="21">${item.name.replace(/&/g, '&amp;')}</text>
      <text align="right" length="5">${item.weight}</text>
      <text align="right" length="8">${parseFloat(item.realPrice).toFixed(2)}</text>
      <text align="right" length="8">${parseFloat((item.realPrice)*(item.weight)).toFixed(2)}</text>
  </line>`;

    if (item.name2 !== null) {
      itemsDetails += `<line>
          <text align="left" length="21">${item.name2}</text>
      </line>`;
    }

    if (item.discountName) {
      itemsDetails += `<line>
          <text align="left" length="34">${item.discountName.replace(/&/g, '&amp;')}</text>
          <text align="right" length="8">-${(item.discount).toFixed(2)} </text>
      </line>`;
    }
    itemsDetails +=`<line></line>`
  });


  printReciptData.payments.forEach(item => {
    paymentTypeAndAmount += `<line>
    <text align="left" length="20">${item.name}</text>
    <text align="right" length="20">${item.amount}</text>
</line>
      `;
  }); if (customerFlag) {
    staffDetails += ` <line><text align="left" length="30" > Staff:@@staff@@</text></line>`
  }
  const groupAndSumItems = (items) => {
    const groupedItems = {};
    items.forEach((item) => {
      if (groupedItems.hasOwnProperty(item.discountName)) {
        groupedItems[item.discountName].value += item.value;
      } else if (parseFloat(item.discount) > 0) {
        groupedItems[item.discountName] = {
          discountName: item.discountName,
          discount: item.discount,
        };
      }
    });
    return Object.values(groupedItems);
  }
  const result = groupAndSumItems(printReciptData.items);

  if (result.length > 0) {
    discountDetails += `<line> <text>---------------------------------------------</text> <text align="center" length="42">----------------------------------------------------</text> </line>`;

    discountDetails += result.map((data) => {
      return `<line><text align="left" length="20">${data.discountName}</text><text align="left" length="10">${data.discount}</text></line>`;
    }).join('');

    discountDetails += `<line> <text>---------------------------------------------</text> <text align="center" length="42">----------------------------------------------------</text> </line>`;
  }
  else {
    discountDetails += `<line> <text>---------------------------------------------</text> <text align="center" length="42">----------------------------------------------------</text> </line>`;
  }
  changeDetails += `<line>
    <text align="left" length="14"> Change</text>
    <text align="left" length="14">${currenciesList[0].isoCode === "USD" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(parseFloat(printReciptData.change).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}</text>
    <text align="left" length="14">${tillData.tillAccess.csBunit.currencies[0].isoCode}</text>
</line>`
  let customer = `<line>
<text align="left" length="22">Customer</text>
<text>${printReciptData.firstName}</text>
</line>`
  let customerName = `
<line>
			<text align="left" length="22">Customer</text>
			<text>${printReciptData.customer.name}</text>
		</line>
`
let discountTotal =`<line></line>
<line size="1">
  <text align ="left" length="25">TOTAL Savings AED</text>
  <text align ="right" length="15">${printReciptData.discount ? (printReciptData.discount).toFixed(2) :""}</text>
</line>
<line size="1">
  <text align ="left" length="30">إجمالي التوفير بالدرهم الإماراتي</text>
</line>
<line></line>`
  let decodedData = tillData.tillAccess.cwrTill.printTemplate.xmlcode2;
  decodedData = decodedData.replaceAll("@@OrderType@@", `${OrderType}`);
  decodedData = decodedData.replaceAll("@@arabicOrderType@@", `${ArabicOrderType}`);
  decodedData = decodedData.replaceAll("@@ORDER_TYPE1_TEXT@@", `${ORDER_TYPE1_TEXT !== '' ? ORDER_TYPE1_TEXT : ''}`);
  decodedData = decodedData.replaceAll("@@date@@", `${printReciptData.salesHistory === "Y" ? printReciptData.orderTime.substring(0, 10) : new Date().toLocaleString().substring(0, 10)}`);
  decodedData = decodedData.replaceAll("@@printReciptDataDocumentno@@", `${printReciptData.documentno ? printReciptData.documentno : ""}`);
  decodedData = decodedData.replaceAll("@@tillIdReceipt@@", printReciptData.documentno ? `${printReciptData.documentno}` : "");
  decodedData = decodedData.replaceAll("@@waiterName@@", `${printReciptData.salesHistory === "Y" ? printReciptData.waiter !== null && printReciptData.waiter !== undefined ? printReciptData.waiter : "" : salesRep[0]?.name === undefined && salesRep[0]?.name == null ? "" : salesRep[0]?.name}`);
  decodedData = decodedData.replaceAll("@@staffDetails@@", `${staffDetails}`);
  decodedData = decodedData.replaceAll("@@staff@@", `${customerFlag ? printReciptData.customer.name : ""}`);
  decodedData = decodedData.replaceAll("@@NoofGuests@@", ``);
  decodedData = decodedData.replaceAll("@@TableName@@", `${printReciptData.salesHistory === "Y" ? printReciptData.table ? printReciptData.table : "" : tableDetails?.table ? "" : tableDetails?.table}`);
  decodedData = decodedData.replaceAll("@@Time@@", `${printReciptData.salesHistory === "Y" ? printReciptData.orderTime.substring(10, printReciptData.orderTime.length - 1) : new Date().toLocaleTimeString()}`);
  decodedData = decodedData.replaceAll("@@dateTime@@", `${printReciptData.orderTime ? printReciptData.orderTime .toLocaleString('en-US', {year: 'numeric',month: '2-digit',day: '2-digit',hour: 'numeric',minute: 'numeric',hour12: true}) : ""}`);
  decodedData = decodedData.replaceAll("@@user@@", `${localStorage.getItem("username")}`);
  decodedData = decodedData.replaceAll("@@TillId@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemName@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemQty@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemPrice@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemTotal@@", `${tillData.tillAccess.cwrTill.till}`);
  decodedData = decodedData.replaceAll("@@itemDetails@@", `${itemsDetails}`);
  decodedData = decodedData.replaceAll("@@discountDetails@@", `${discountDetails}`);
  decodedData = decodedData.replaceAll("@@discountAmount@@", ``);
  decodedData = decodedData.replaceAll("@@changeDetails@@", `${changeDetails}`);
  decodedData = decodedData.replaceAll("@@change@@", `${showOnlyPaymentMethod ? "" : parseFloat(printReciptData.change).toFixed(2)}`);
  decodedData = decodedData.replace("@@paymentType@@", printReciptData.payments[0]?.name ? `${printReciptData.payments[0].name}` : "");
  decodedData = decodedData.replace("@@paymentTypeAndAmount@@", `${paymentTypeAndAmount}`);
  decodedData = decodedData.replace("@@paymentAmount@@", printReciptData.payments[0]?.amount ? `${parseFloat(printReciptData.payments[0].amount).toFixed(2)}` : "");
  decodedData = decodedData.replaceAll("@@Total@@", `${printReciptData.total ? parseFloat(printReciptData.total).toFixed(2) : ""}`);
  decodedData = decodedData.replaceAll("@@KIP@@", '');
  decodedData = decodedData.replaceAll("@@THB@@", '');
  decodedData = decodedData.replaceAll("@@USD@@", '');
  decodedData = decodedData.replaceAll("@@EUR@@", '');
  decodedData = decodedData.replaceAll("@@CNY@@", '');

  // decodedData = decodedData.replaceAll("@@KIP@@", `${currenciesList[0].isoCode === "USD" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat(printReciptData.total.toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@THB@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * THB[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@USD@@", `${currenciesList[0].isoCode === "USD" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * USD[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@EUR@@", `${currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "EUR" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * EUR[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  // decodedData = decodedData.replaceAll("@@CNY@@", `${currenciesList[0].isoCode === "CNY" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US") : currenciesList[0].isoCode === "LAK" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("lo-LA") : currenciesList[0].isoCode === "INR" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-IN") : currenciesList[0].isoCode === "CNY" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-GB") : currenciesList[0].isoCode === "AED" ? parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("ar-AE") : parseFloat((printReciptData.total * CNY[0]?.currencyToRate).toFixed(roundOffValue[0]?.prcPrecision)).toLocaleString("en-US")}`);
  decodedData = decodedData.replaceAll("@@Payments1@@", `${Payments1}`);
  decodedData = decodedData.replaceAll("@@taxDetails@@", `${taxDetails}`);
  decodedData = decodedData.replaceAll("@@taxTotal@@", `${ parseFloat(printReciptData.tax).toFixed(2)}`);
  decodedData = decodedData.replaceAll("@@Payment Status@@", "Paid");
  decodedData = decodedData.replaceAll("@@status@@", `${paymentStatus}`);
  decodedData = decodedData.replaceAll("@@arabicStatus@@", `${arabicStatus}`);
  decodedData = decodedData.replaceAll("@@Customer@@", `${printReciptData.customer.name !== "Anonymous Customer" ? `${customerName}` : ""}`);
  decodedData = decodedData.replaceAll("@@discountTotal@@", `${!printReciptData.isReturn && printReciptData.discount !== 0 ? `${discountTotal}` : ""}`);
  Axios
    .post(`${printerURL}printer`, decodedData, {
      headers: {
        'Content-Type': 'application/xml; charset=UTF-8',
        'Accept': 'application/xml'
      }
    })
    .then(() => {
      console.log("XML print success !");
    })
    .catch((response) => {
      console.log("XML print failed1 !", response);
    });
}
export default HCPrint;